/* eslint-disable no-confusing-arrow */
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import BidButton from '../BidButtons/BidButton';
import Widget from '../BidButtons/Widget';
import { lotPropTypes } from '../PropTypes/proptypes';
import formatEstimate from '../utils/formatEstimate';
import setLineBreaks from '../utils/setLineBreaks';
// import { map } from 'lodash';

const targetSaleNumberAndLotNumberRange = (targetSaleNumber, lotNumberStart, lotNumberEnd, lot) => {
  const isTargetSaleNumberEqualSaleNumber = targetSaleNumber === lot?.saleNumber;
  const isLotNumberFullInTheRange = Number(lotNumberStart) <= Number(lot?.lotNumberFull) && Number(lot?.lotNumberFull) <= Number(lotNumberEnd);

  if ( isTargetSaleNumberEqualSaleNumber && isLotNumberFullInTheRange) {
    return true;
  }

  return false;
}

const LotEstimate = props => {
  let estimate = null;
  const { getI18nLabel } = props;
  if (props.saleTypeId === 5 && props.buyNowPrice > 1) {
    return (
      <p className="phillips-lot__description__estimate phillips-lot__description__estimate--buy-now">
        <strong className="phillips-lot__description__estimate__strong">{getI18nLabel({ label: 'price' })}</strong>
        &nbsp;
        {props.currencySign}
        {formatEstimate(props.buyNowPrice)}
        &nbsp;
        {props.estimateSpecialChar}
        <br />
      </p>
    );
  }
  if (props.estimateText !== null && props.estimateText.length > 0) {
    // if there is estimateText
    return (
      <p className="phillips-lot__description__estimate">
        <span>
          {camelCase(props.estimateText) === 'priceUponRequest'
            ? getI18nLabel({ label: camelCase(props.estimateText) })
            : props.estimateText}
        </span>
      </p>
    );
  }
  if (props.lowEstimate) {
    // if there is a lowEstimate
    if (props.saleTypeId === 4) {
      if (props.showSaleOffers) {
        return (
          <p className="phillips-lot__description__estimate">
            <span>{getI18nLabel({ label: 'askingPrice' })}</span>
            &nbsp;
            {props.currencySign}
            {formatEstimate(props.lowEstimate)}
            &nbsp;
            {props.estimateSpecialChar}
            <br />
          </p>
        );
      }
    }
    return (
      <p className="phillips-lot__description__estimate">
        <span>{getI18nLabel({ label: 'estimate' })}</span>
        &nbsp;
        <span className="phillips-lot__description__estimate__price">
          <span>
            {props.currencySign}
            {formatEstimate(props.lowEstimate)}
          </span>
          &nbsp;-&nbsp;
          <span>{formatEstimate(props.highEstimate)}</span>
          {isEmpty(props.estimateSpecialChar) ? null : (
            <React.Fragment>
              &nbsp;
              {props.estimateSpecialChar}
            </React.Fragment>
          )}
        </span>
        <br />
      </p>
    );
  }
  if (!props.isExhibition) {
    if (props.saleTypeId === 5) {
      estimate = (
        <p className="phillips-lot__description__estimate--buy-now">
          <span className="phillips-lot__description__estimate__strong">{getI18nLabel({ label: 'inquire' })}</span>
        </p>
      );
    }
    estimate = <p>{getI18nLabel({ label: 'estimateOnRequest' })}</p>;
  }
  return estimate;
};

const showHammer = props => {
  const { getI18nLabel } = props;
  if (props.saleTypeId === 4) {
    return props.showSoldPrice ? <p className="phillips-lot__sold">{getI18nLabel({ label: 'sold' })}</p> : null;
  }
  if (props.hammerPlusBP > 0) {
    if (props.saleTypeId === 5 && props.showEstimateText && !props.showSoldPrice && !props.isSoldOverride) {
      return;
    }
    if (!props.showSoldPrice) {
      return <p className="phillips-lot__sold">{getI18nLabel({ label: 'sold' })}</p>;
    }
    // fix for WEB-4667 [PERPETUAL] - Change sold price text logic to be based on sale number EX080519

    // if (props.saleTypeId === 5 && !props.showSoldPrice) {
    if (props.saleTypeId === 5 && props.saleNumber !== 'EX011621') {
      return <p className="phillips-lot__sold">{getI18nLabel({ label: 'sold' })}</p>;
    }
    return (
      <p className="phillips-lot__sold">
        {getI18nLabel({ label: 'soldFor' })}
        &nbsp;
        {props.currencySign}
        {formatEstimate(props.hammerPlusBP)}
      </p>
    );
  }

  if (props.isSoldOverride && (!props.hammerPlusBP || props.hammerPlusBP <= 0)) {
    return <p className="phillips-lot__sold">{getI18nLabel({ label: 'sold' })}</p>;
  }
  return null;
};

const toggleEstimateHammer = props =>
  props.hammerPlusBP > 0 ? showHammer(props) : props.showEstimateText ? <LotEstimate {...props} /> : null;

const LotDescription = props => {
  // This is for sale UK040221 to add utimate label to lots
  let imgsrc = '';
  if (props.saleNumber === 'UK040221') {
    if (
      props.lotNumberFull === '1' ||
      props.lotNumberFull === '2' ||
      props.lotNumberFull === '3' ||
      props.lotNumberFull === '4' ||
      props.lotNumberFull === '5' ||
      props.lotNumberFull === '6' ||
      props.lotNumberFull === '7' ||
      props.lotNumberFull === '8' ||
      props.lotNumberFull === '9' ||
      props.lotNumberFull === '10'
    ) {
      imgsrc = '/images/Ultimate_Irving_Badge.png';
    } else if (
      props.lotNumberFull === '21' ||
      props.lotNumberFull === '22' ||
      props.lotNumberFull === '23' ||
      props.lotNumberFull === '24' ||
      props.lotNumberFull === '25' ||
      props.lotNumberFull === '26' ||
      props.lotNumberFull === '27' ||
      props.lotNumberFull === '28' ||
      props.lotNumberFull === '29' ||
      props.lotNumberFull === '20'
    ) {
      imgsrc = '/images/Ultimate_Badge.png';
    }
  } else if (
    (props.saleNumber === 'HK010321' && (props.lotNumberFull === '41' || props.lotNumberFull === '50')) ||
    (props.saleNumber === 'HK010421' &&
      (props.lotNumberFull === '129' ||
        props.lotNumberFull === '130' ||
        props.lotNumberFull === '131' ||
        props.lotNumberFull === '132' ||
        props.lotNumberFull === '133' ||
        props.lotNumberFull === '134' ||
        props.lotNumberFull === '135' ||
        props.lotNumberFull === '136' ||
        props.lotNumberFull === '137' ||
        props.lotNumberFull === '138' ||
        props.lotNumberFull === '139' ||
        props.lotNumberFull === '140' ||
        props.lotNumberFull === '141' ||
        props.lotNumberFull === '142' ||
        props.lotNumberFull === '143' ||
        props.lotNumberFull === '144' ||
        props.lotNumberFull === '145' ||
        props.lotNumberFull === '146' ||
        props.lotNumberFull === '147' ||
        props.lotNumberFull === '148' ||
        props.lotNumberFull === '149' ||
        props.lotNumberFull === '150' ||
        props.lotNumberFull === '151' ||
        props.lotNumberFull === '152' ||
        props.lotNumberFull === '153' ||
        props.lotNumberFull === '154' ||
        props.lotNumberFull === '155' ||
        props.lotNumberFull === '156' ||
        props.lotNumberFull === '157' ||
        props.lotNumberFull === '158'))
  ) {
    imgsrc = '/images/UltraNeo-Badge.svg';
  } else if (props.saleNumber === 'HK010122') {
    if (
      [...Array(15).keys()]
        .map(i => i + 35)
        .map(String)
        .includes(props.lotNumberFull)
    ) {
      imgsrc = '/images/UltraNeo-Badge.svg';
    }
  } else if (props.saleNumber === 'HK010222') {
    if (
      [...Array(41).keys()]
        .map(i => i + 263)
        .map(String)
        .includes(props.lotNumberFull)
    ) {
      imgsrc = '/images/UltraNeo-Badge.svg';
    }
  } else if (props.saleNumber === 'UK010323') {
    if (
      [...Array(26).keys()]
        .map(i => i + 76)
        .map(String)
        .includes(props.lotNumberFull)
    ) {
      imgsrc = '/images/Belilty-Badge.svg';
    }
  } else if (props.saleNumber === 'HK010322') {
    if (
      [...Array(9).keys()]
        .map(i => i + 27)
        .map(String)
        .includes(props.lotNumberFull)
    ) {
      imgsrc = '/images/UltraNeo-Badge.svg';
    }
  } else if (props.saleNumber === 'UK040122') {
    if (['34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45'].includes(props.lotNumberFull)) {
      imgsrc = '/images/Ultimate.svg';
    } else if (['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13'].includes(props.lotNumberFull)) {
      imgsrc = '/images/In-Focus-Peter-Beard.svg';
    }
  } else if (props.saleNumber === 'UK040222') {
    if (['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32'].includes(props.lotNumberFull)) {
      imgsrc = '/images/Cotswolds.svg';
    } else if (['52', '53', '54', '55', '56', '57', '58', '59', '60', '61', '62', '63', '64'].includes(props.lotNumberFull)) {
      imgsrc = '/images/Ultimate.svg';
    } else if (['120','121', '122', '123', '124', '125', '126', '127', '128', '129', '130', '131', '132', '133', '134', '135', '136', '137', '138', '139'].includes(props.lotNumberFull)) {
      imgsrc = '/images/UltimatePink.svg';
    }
  }else if (props.saleNumber === 'NY040123'){
    if(
      [...Array(38).keys()]
        .map(i => i + 203)
        .map(String)
        .includes(props.lotNumberFull)
      ){
      imgsrc = '/images/Bunnell-Badge.svg';
    }else if (
      [...Array(11).keys()]
        .map(i => i + 31)
        .map(String)
        .includes(props.lotNumberFull)
      ){
      imgsrc = '/images/Apraxine-Badge.svg';
    }else if(
      [...Array(21).keys()]
        .map(i => i + 283)
        .map(String)
        .includes(props.lotNumberFull)
    ){
      imgsrc = '/images/Esman-Badge.svg';
    }
  }else if (props.saleNumber === 'UK040123'){
    if(
      [...Array(12).keys()]
        .map(i => i + 10)
        .map(String)
        .includes(props.lotNumberFull)
      ){
      imgsrc = '/images/Ultimate-UK040123.svg';
    }else if (
      [...Array(39).keys()]
        .map(i => i + 130)
        .map(String)
        .includes(props.lotNumberFull)
      ){
      imgsrc = '/images/SpotlightCollection.svg';
    }
  }else if (props.saleNumber === 'NY010423'){
    if(
      [...Array(40).keys()]
        .map(i => i + 157)
        .map(String)
        .includes(props.lotNumberFull)
      ){
      imgsrc = '/images/Esman-Collection.svg';
  }
  }else if (props.saleNumber === 'UK010423'){
    if(
      [...Array(24).keys()]
        .map(i => i + 64)
        .map(String)
        .includes(props.lotNumberFull)
      ){
      imgsrc = '/images/Lemann-Collection.svg';
  }
}else if (props.saleNumber === 'NY010623'){
  if(
    [...Array(7).keys()]
      .map(i => i + 177)
      .map(String)
      .includes(props.lotNumberFull)
    ){
    imgsrc = '/images/Lemann-Collection.svg';
}else if (
    [...Array(22).keys()]
      .map(i => i + 93)
      .map(String)
      .includes(props.lotNumberFull)
    ){
    imgsrc = '/images/Esman-Collection.svg';
    }
}else if (props.saleNumber === 'NY030423'){
  if(
    [...Array(30).keys()]
      .map(i => i + 1)
      .map(String)
      .includes(props.lotNumberFull)
    ){
    imgsrc = '/images/Provincetown-Printers-Badge.svg';
    }else if (['83', '84', '180', '185', '186', '187', '190', '265', '266', '267', '268', '276'].includes(props.lotNumberFull)) {
    imgsrc = '/images/Lemann-Collection.svg';
  }
}else if (props.saleNumber === 'UK040323'){
  if(
    [...Array(14).keys()]
      .map(i => i + 1)
      .map(String)
      .includes(props.lotNumberFull)
    ){
    imgsrc = '/images/Ultimate.svg'; 
    }else if (
      [...Array(18).keys()]
      .map(i => i + 85)
      .map(String)
      .includes(props.lotNumberFull)
      ) {
    imgsrc = '/images/Steven-Klein-Badge.svg'; 
  }
  }else if (props.saleNumber === 'NY030323'){
    if (['38', '40', '57', '83', '85', '89'].includes(props.lotNumberFull)) {
      imgsrc = '/images/Esman-Collection.svg';
  }
  }else if (props.saleNumber === 'NY030223'){
    if(
      [...Array(67).keys()]
        .map(i => i + 1)
        .map(String)
        .includes(props.lotNumberFull)
      ){
      imgsrc = '/images/Esman-Badge-Yellow.svg';
  }
  }else if (props.saleNumber === 'UK030124'){
    if(
      [...Array(40).keys()]
        .map(i => i + 13)
        .map(String)
        .includes(props.lotNumberFull)
      ){
      imgsrc = '/images/Editions-Schellmann.svg';
  }
  }else if (props.saleNumber === 'NY030124'){
    if (['11', '12', '13', '22', '29', '46', '49', '60', '61', '62', '64', '65', '66', '69', '70', '71', '73', '74', '80'].includes(props.lotNumberFull)) {
      imgsrc = '/images/Robert-Bechtle-Estate.svg';
  }
  }else if (props.saleNumber === 'NY040323'){
    if(
      [...Array(40).keys()]
        .map(i => i + 161)
        .map(String)
        .includes(props.lotNumberFull)
      ){
      imgsrc = '/images/bidwell-badge.svg';
  }
  }else if (props.saleNumber === 'NY040124'){
    if (['317','318','319','320','321','322','323','324','325','326','327','328','329','330','331','332','333','334','335','336','337','338','339','340','341','342','343','344','345','346','347','348','349','350','351','352','353','354'].includes(props.lotNumberFull)) {
      imgsrc = '/images/FigureAndForm.svg';
    }
  } else if (targetSaleNumberAndLotNumberRange("UK040124", 1, 10, props)) {
      
    imgsrc = '/images/ultimate.svg';
  } else if (targetSaleNumberAndLotNumberRange("UK040124", 121, 170, props)) {
    
    imgsrc = '/images/de_pury_collection_badge.svg';
  }else if(props.saleNumber === 'HK070124') {
    if(
      [...Array(9).keys()]
        .map(i => i + 10)
        .map(String)
        .includes(props.lotNumberFull) ||
        [...Array(9).keys()]
        .map(i => i + 64)
        .map(String)
        .includes(props.lotNumberFull)
      ) {
        imgsrc = '/images/Steven-Klein-Badge.svg';
      }
  }else if(props.saleNumber === 'NY030524'){
    if(['2','3','4','13','14','15','17','24','33','36','38','42','43','44','48','49','57','60','63','64','65','66','68','69','70','73','80','81','85','86','94','96','97','98','99','111','124','125','129','132','135','144','155','157','158','165','166','179','181','184','190'].includes(props.lotNumberFull)){
      imgsrc = '/images/LESPrintshop.svg';
    }
  }

  const hideBrightCoveVideo = props.brightCoveVideoID && !props.hasObjectVideo

  return (
    <a
      className={classNames('phillips-lot__description', {
        'phillips-lot__description--is-watch': props.isWatch || props.isMixedAuction,
        'phillips-lot__description--buy-now': props.saleTypeId === 5,
        'phillips-lot__description--has-hammer': props.hammerPlusBP > 0 || props.saleTypeId === 4
      })}
      href={props.detailLink}
    >
      <p className={classNames('phillips-lot__description__lot-number-wrapper')}>
        {props.showLotNumber || props.isNoReserve ? (
          <span className="phillips-lot__description__lot-number-wrapper__text-wrapper">
            {props.showLotNumber ? (
              <strong className="phillips-lot__description__lot-number-wrapper__lot-number">
                {props.lotNumberFull.trim()}
              </strong>
            ) : null}
            {props.isNoReserve && (props.timeState !== 3 || !props.endSale) ? (
              <strong className="phillips-lot__description__lot-number-wrapper__no-reserve">
                {props.getI18nLabel({ label: 'noReserve' })}
              </strong>
            ) : null}
          
            
          </span>
        ) : null}
        {imgsrc ? (
          <span className="phillips-lot__description__ultimate-text">
            <img src={imgsrc} alt="ultimate label text" />
          </span>
            ) : null}
        {(props.videoSource && props.videoSource.length > 0) || props.is360View ? (
          <span className="phillips-lot__description__lot-number-wrapper__img-wrapper">
            {props.is360View ? (
              <img
                className="phillips-lot__has-360"
                height={34}
                src="https://phillips.vo.llnwd.net/v1/web_prod/images/icons/360icons/360icon.png"
                alt="360 View"
                title="360 View"
                width={50}
              />
            ) : null}
            {props.videoSource && props.videoSource.length > 0 && !hideBrightCoveVideo ? (
              <img
                alt="Video Link"
                className="phillips-lot__has-video"
                height={17}
                src="https://phillips.vo.llnwd.net/v1/web_prod/images/icons/video-icon.svg"
                title="Video Link"
                width={17}
              />
            ) : null}
          </span>
        ) : null}
      </p>
      {props.makerId ? (
        <p
          className={classNames('phillips-lot__description__artist', {
            'phillips-lot__description__artist--buy-now': props.saleTypeId === 5
          })}
          title={`${props.getTranslatedString('makerName')}`}
        >
          {/* shows artist name in Chinese when ZH */}
          {props.getTranslatedString('makerName')}
        </p>
      ) : null}
      <p
        className={classNames('phillips-lot__description__title', {
          'phillips-lot__description__title--no-makerName': !props.makerId
        })}
        title={`${props.getTranslatedString('description')}`}
      >
        {props.wReferenceNo && props.wReferenceNo.length > 0 ? (
          <span
            className={classNames({
              'phillips-lot__description__ref-num--buy-now': props.saleTypeId === 5
            })}
          >
            {props.getI18nLabel({
              label: 'referenceNumberShort',
              args: [props.wReferenceNo]
            })}
          </span>
        ) : null}
        {props.wReferenceNo && props.wReferenceNo.length > 0 ? <br /> : null}
        {props.wModelName && props.wModelName.length > 0 ? (
          <span>
            Model:&nbsp;
            {props.wModelName}
            &nbsp;
          </span>
        ) : null}
        {props.wModelName && props.wModelName.length > 0 ? <br /> : null}
        {props.getTranslatedString('descriptionWithMarkup') &&
        props.getTranslatedString('descriptionWithMarkup').length > 0 ? (
          <span
            dangerouslySetInnerHTML={{
              __html: setLineBreaks(props.getTranslatedString('descriptionWithMarkup'))
            }}
          />
        ) : (
          <em>{props.getTranslatedString('description')}</em>
        )}
      </p>
      {props.toggleEstHammer ? toggleEstimateHammer(props) : props.showEstimateText ? <LotEstimate {...props} /> : null}
      {props.toggleEstHammer ? null : showHammer(props)}
      {props.showSaleTitle ? (
        <p className="phillips-lot__sale-info">{`Lot ${props.lotNumber} \u{2022} ${props.saleTitle}`}</p>
      ) : null}
      {props.showObjectNumber ? <p className="phillips-lot__sale-info__object-number">{props.objectNumber}</p> : null}
      {props.isNoLot || !props.showBidButton || props.isSoldOverride ? null : props.saleTypeId === 6 ? (
        <Widget {...props} auctionMobilityLotRowIds={props.auctionMobilityLotRowIds} layout="grid" />
      ) : (
        <BidButton
          auctionMobilityLotRowIds={props.auctionMobilityLotRowIds}
          layout="grid"
          lot={{
            ...props,
            saleTypeId: 3,
            auctionBidPartner: 1
          }}
          
        />
      )}
    </a>
  );
};

LotDescription.defaultProps = {
  auctionMobilityLotRowIds: [],
  isWatch: false,
  isNoLot: false,
  showSaleTitle: false,
  showBidButton: false
};

LotDescription.propTypes = {
  ...lotPropTypes,
  auctionMobilityLotRowIds: PropTypes.arrayOf(PropTypes.string),
  getTranslatedString: PropTypes.func.isRequired,
  isNoLot: PropTypes.bool,
  isWatch: PropTypes.bool,
  saleTypeId: PropTypes.number.isRequired,
  showBidButton: PropTypes.bool,
  showSaleTitle: PropTypes.bool,
  toggleEstHammer: PropTypes.bool.isRequired
};

export default LotDescription;
